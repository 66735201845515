$togggle-offset: 30px;

.formcontrol {
  @include clearfix;
  margin: 0 0 $form-spacing;
  max-width: $fieldset-max-width;
  padding: 0;
  &__inner {
    position: relative;
  }
  &__label {
    z-index: 2;
  }

  // focus bar
  &__bar {
    position: relative;
    z-index: 3;
    &::before {
      left: 50%;
    }
    &::after {
      right: 50%;
    }
    &::after,
    &::before {
      background: $primary-color;
      bottom: 0;
      content: '';
      height: 2px;
      position: absolute;
      transition: all 250ms ease;
      width: 0;
    }
  }
  input,
  textarea,
  select {
    &:focus,
    &.active {
      border-color: $jumbo;
      + .formcontrol__bar::after {
        width: 50%;
      }
      + .formcontrol__bar::before {
        width: 50%;
      }
    }
  }

  // required
  &.required {
    .formcontrol__label::after {
      content: ' *';
    }
  }
  &.error {
    input,
    select,
    textarea,
    .select2-selection,
    .question & .tox-tinymce,
    .answer & .tox-tinymce {
      border-color: $alert-color;
    }
  }

  &--fullwidth {
    max-width: 100%;
    input,
    textarea,
    select {
      width: 100%;
    }
  }

  // checkbox and radio list
  &--checkboxfield,
  &--checkboxlist .formcontrol__list,
  &--radiobuttonlist .formcontrol__list {
    label {
      color: $body-font-color;
      cursor: pointer;
      font-size: 1rem;
      font-weight: $global-weight-medium;
      padding: 0 0 0 $togggle-offset;
      position: relative;
    }
    .toggle__container {
      left: 0;
      margin: 0;
      position: absolute;
      top: .2rem;
    }
    input:disabled + span {
      cursor: not-allowed;
    }
    .formcontrol__sublabel {
      display: block;
      font-size: $small-font-size;
    }
  }
  &--checkboxfield,
  &--checkboxlist,
  &--radiobuttonlist {
    &:not(.formcontrol--horizontal) .formcontrol__error,
    &:not(.formcontrol--horizontal) .help-text {
      padding-left: $togggle-offset;
    }
  }

  &--checkboxfield {
    .formcontrol__inner {
      display: inline-block;
    }
  }

  &--radiobuttonlist.formcontrol--cardlist,
  &--checkboxlist.formcontrol--cardlist {
    margin-bottom: 0;
    .formcontrol__list {
      label {
        margin-bottom: 0;
        + label {
          margin-top: 1rem;
        }
      }
      .formcontrol__card-label {
        display: flex;
        flex-flow: row wrap;
        padding-left: 0;
        width: 100%;
        &-text {
          align-items: center;
          border-top: 1px solid $gainsboro;
          display: flex;
          flex: 1 1 auto;
          flex-flow: row nowrap;
          padding: .5rem;
        }
        img {
          flex: 1 1 100%;
          width: 100%;
        }
        .toggle__container {
          display: inline-block;
          flex: 0 0 auto;
          left: auto;
          margin-right: .5rem;
          position: relative;
          top: 0;
        }
        &.active {
          border-color: $primary-color;
        }
        &.inactive {
          opacity: 1;
        }
      }
    }
  }

  &__list {
    @include clearfix;
    label {
      clear: both;
      float: left;
      &.inactive {
        opacity: .82;
      }
      &.disabled {
        opacity: .5;
      }
      + label {
        margin-top: .5rem;
      }
    }
  }

  &--saving {
    background: url('../images/deadlyduck/loader.gif') no-repeat right top;
  }

  // select
  select {
    margin-top: .5rem;
  }
  &--select {
    .formcontrol__label {
      pointer-events: none;
    }
    select {
      &:focus {
        background-color: $white;
        outline: none;
      }
    }
    &.formcontrol--hasvalue select {
      color: $input-color;
    }
  }

  // code editor

  // fix paddings
  input,
  select {
    padding-left: 0;
    padding-right: 0;
  }

  &--textarea,
  &--richtext,
  &--radiobuttonlist,
  &--checkboxlist,
  &--switch {
    .formcontrol__label {
      margin: 0 0 .5rem;
    }
  }
  .formcontrol__label:not(.show-for-sr) {
    + .mce-panel,
    + .js-show-cropper ~ .imagefield-wrapper,
    + .imagefield-wrapper {
      margin-top: .5rem;
    }
  }

  &--border {
    input,
    select,
    textarea {
      border: 1px solid $silver;
      border-radius: $global-radius;
      padding: ($form-spacing * .5);
      &:focus,
      &:hover {
        background-color: $white;
        border: 1px solid $base;
      }
      &:focus {
        box-shadow: 0 0 5px $medium-gray;
        &.large {
          box-shadow: 0 0 10px $medium-gray;
        }
      }
      &.large {
        padding: 1rem 2rem;
      }
    }
    .mce-edit-area {
      border: 0;
    }
    .formcontrol__bar {
      display: none;
    }
  }

  &--autowidth {
    .formcontrol__inner {
      max-width: 100%;
    }
    .formcontrol__label {
      max-width: 100%;
      white-space: nowrap;
    }
  }

  // inline
  &--inline {
    .formcontrol__label {
      display: inline-block;
      margin-right: 1rem;
    }
  }

  &--no-margin-bottom {
    margin-bottom: 0;
  }
}

.grid-view,
.table--data {
  .formcontrol--checkboxfield {
    align-items: center;
    display: flex;
    justify-content: flex-start;
  }
}

.card,
fieldset {
  > .formcontrol:last-of-type {
    margin-bottom: 0;
    .reveal-modal & {
      margin-bottom: $form-spacing * 2;
    }
  }
}

.js-show-cropper {
  color: $body-font-color;
  text-align: left;
  &:hover,
  &:focus {
    color: $primary-color;
  }
}

@include breakpoint(medium) {
  .formcontrol {
    margin: 0 0 $form-spacing * 2 0;

    // auto width
    &--autowidth {
      input,
      select,
      textarea {
        width: auto;
      }
    }

    // horizontal radio and checkbox list
    &--horizontal {
      .formcontrol {
        &__inner {
          min-height: 53px;
        }
        &__list {
          margin: -.25rem -.75rem;
          label {
            display: inline-block;
            float: none;
            margin: .25rem .75rem;
            vertical-align: bottom;
          }
        }
      }
    }

    // checkboxfield without label text
    &--checkboxfield-nolabel {
      display: inline-block;
      height: 18px;
      width: 18px;
      .column--checkbox & {
        width: auto;
      }
      .formcontrol__inner {
        height: 18px;
        position: relative;
        width: 18px;
      }
      .formcontrol__label {
        height: 18px;
        left: 0;
        padding: 0;
        position: absolute;
        top: 0;
        width: 18px;
      }
      .toggle__container {
        margin: 0;
        position: relative;
        top: 0;
      }
    }
    &--no-margin-bottom {
      margin-bottom: 0;
    }
  }
}
